export function onHoldCall(e) {
  const notificationPopupEl = document.querySelector(
    ".on-hold-indication-popup"
  );
  const toggleCameraEl = document.getElementById("toggleCamera");
  const toggleAudioEl = document.getElementById("toggleAudio");
  const moreOptionsControlContEl = document.querySelector(
    ".saleassist-video-call-more-options-control"
  );

  if (notificationPopupEl.classList.value.includes("on-hold-indication-popup-show")) {
    if(this.localVideoTrackMuted) {
      toggleCameraEl.click();
    }

    if(this.localAudioTrackMuted) {
      toggleAudioEl.click();    
    }

    e.style.color = "#fff";
    notificationPopupEl.classList.remove("on-hold-indication-popup-show");

    if (this.meeting_data.people_id) {
      this.socket.emit("chat_message", {
        hold: false,
        people_id: this.meeting_data.people_id,
      });
    } else {
      this.socket.emit("chat_message", {
        hold: false,
        meeting_id: this.meeting_data.id,
      });
    }
  } else {
    if(!this.localVideoTrackMuted) {
      toggleCameraEl.click();
    }

    if(!this.localAudioTrackMuted) {
      toggleAudioEl.click();    
    }

    e.style.color = "#ff0000";
    notificationPopupEl.classList.add("on-hold-indication-popup-show");

    if (this.meeting_data.people_id) {
      this.socket.emit("chat_message", {
        hold: true,
        people_id: this.meeting_data.people_id,
      });
    } else {
      this.socket.emit("chat_message", {
        hold: true,
        meeting_id: this.meeting_data.id,
      });
    }
  }

  moreOptionsControlContEl.classList.remove("more-options-control-active");
}
