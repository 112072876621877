import React from "react";
import ReactDOM from "react-dom";
import { config } from "../../../config";
import { postCall, endMeeting, getCall } from "../../../utils/methods";
import { ColorRing } from "react-loader-spinner";

export async function leaveChannel(el, isEndEveryone = false) {
  var temp = el.innerHTML;
  try {
    el.disabled = true;
    ReactDOM.render(
      <ColorRing
        visible={true}
        height="20"
        width="20"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
      />,
      el
    );

    // Stop playing and publishing tracks
    if (this.localVideoTrack && this.message_type === "video_call") {
      this.localVideoTrack.stop();
      this.localVideoTrack.close();
    }

    if (this.localAudioTrack) {
      this.localAudioTrack.stop();
      this.localAudioTrack.close();
    }

    if (this.localScreenTrack) {
      if (this.localScreenTrack?.length > 0) {
        this.localScreenTrack.forEach((track) => {
          track.stop();
          track.close();
        });
      } else {
        this.localScreenTrack.stop();
        this.localScreenTrack.close();
      }

      await this.screen_client.leave();
    }

    // Clean up remote tracks
    this.remoteUsers = {};

    // Unpublish local tracks
    if (
      this.localVideoTrack &&
      this.localAudioTrack &&
      this.message_type === "video_call"
    ) {
      await this.client.unpublish([this.localAudioTrack, this.localVideoTrack]);
    }
    if (this.localAudioTrack && this.message_type === "audio_call") {
      await this.client.unpublish([this.localAudioTrack]);
    }

    // removing observer for live streaming
    if (
      this.meeting_data.meeting_type === "live_streaming" &&
      this.resizeObserver
    ) {
      this.resizeObserver.disconnect();
    }

    // Leave the channel
    await this.client.leave();

    this.chatLogObserver.disconnect();

    if (isEndEveryone) {
      endMeeting({
        meeting: {
          id: this.meeting_data.id,
        },
        onSuccess: () => {},
      });
    } else {
      getCall({
        url: config.url.MEETINGS,
        path: `/end/${this.meeting_data.id}/true`,
        onSuccess: (response) => {
          // console.log(response.data, "unregister user session socket")
        },
        onFailure: (error) => {
          console.log("error: ", error);
        },
      });
    }

    if (this.meeting_data.meeting_type === "conference") {
      postCall({
        url: config.url.SOURCE,
        path: `/unregister_user_session_socket/${this.meeting_data.id}/${this.UserContext.user_name}`,
        onSuccess: (response) => {
          // console.log(response.data, "unregister user session socket")
        },
        onFailure: (error) => {},
      });
    }

    // this.socket.disconnect();
    this.socket.emit("meeting_left", {
      meeting_id: this.meeting_data.id,
      uid: this.UserContext.email,
    });

    this.socket.removeAllListeners("message");
    this.socket.removeAllListeners("chat_message");

    this.setSocketContext((prev) => ({
      ...prev,
      agoraClient: "",
      agoraLocalAudioTrack: "",
      agoraLocalVideoTrack: "",
    }));

    if (this.end_callback) {
      await this.end_callback(isEndEveryone);
    }
  } catch (error) {
    el.innerHTML = temp;
    el.disabled = false;
    console.error("Failed to leave the channel:", error);
  }
}
